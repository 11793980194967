import { httpClientBot, httpPlainBotApi } from '@/libraries';

const state = {
    listAssistant: [],
    files: [],
    playground: [],
    vectorFiles: [],
    assistantMessage: false,
    models: [],
};

const getters = {
    assistant(state) {
        return state.listAssistant;
    },
    files(state) {
        return state.files;
    },
    playground(state) {
        return state.playground;
    },
    vectorFiles(state) {
        return state.vectorFiles;
    },
    assistantMessage(state) {
        return state.assistantMessage;
    },
    threadUnique(state) {
      return state.thread_unique;
    }
};

const mutations = {
    SET_ASSISTANT(state, data) {
        state.listAssistant = data;
    },
    SET_FILES(state, data) {
        state.files = data;
    },
    ADD_PLAYGROUND(state, data) {
        state.playground.push(data);
    },
    CLEAR_PLAYGROUND(state) {
        state.playground = [];
    },
    MOD_PLAYGROUND(state, data) {
        state.playground[state.playground.length - 1].message += data;
    },
    SET_VECTORFILES(state, data) {
        state.vectorFiles = data;
    },
    SET_ASSISTANT_MESSAGE(state, data) {
        state.assistantMessage = data;
    },
    SET_MODELS(state, data) {
      state.models = data;
    } ,
    SET_THREAD_UNIQUE(state, data) {
      state.thread_unique = data;
    } ,
};

const actions = {
    async listAssistant({ commit, rootGetters }) {
        const resp = await httpPlainBotApi.get(rootGetters.appId.hashed + '/assistant');
        // console.log(resp.data.data.data);
        commit('SET_ASSISTANT', resp.data.data);
    },
    async create({ rootGetters }, payload) {
        const resp = httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/assistant', payload);
        return resp;
    },
    async getById({ rootGetters }, id) {
        const resp = await httpPlainBotApi.get('/' + rootGetters.appId.hashed + '/assistant/detail/' + id);
        console.log(resp);
        return resp.data.data;
    },
    async update({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.put('/' + rootGetters.appId.hashed + '/assistant/' + payload.id, payload);
        console.log(resp);
    },
    async delete({ rootGetters }, id) {
        const resp = await httpPlainBotApi.delete('/' + rootGetters.appId.hashed + '/assistant/' + id);
        console.log(resp);
    },
    async getFile({ rootGetters, commit }, params = null) {
        let type = '';
        if (params) {
          if (params.type) {
            type = `?type=${params.type}`;
          }
        }
        const resp = await httpPlainBotApi.get('/' + rootGetters.appId.hashed + '/assistant/file' + type);
        commit('SET_FILES', resp.data.data);
    },
    async uploadFile({ rootGetters, commit }, payload) {
        const resp = await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/assistant/file', payload);
        console.log(resp);
        return resp.data;
    },
    async deleteFile({ rootGetters, commit }, id) {
        const resp = httpPlainBotApi.delete('/' + rootGetters.appId.hashed + '/assistant/file/' + id);
        console.log(resp);
    },
    async attach({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/assistant/attach-file', payload);
        console.log(resp);
    },
    async detach({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/assistant/detach-file', payload);
        console.log(resp);
        return resp.data;
    },
    async sendPlayground({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/assistant/playground/send', payload);
        return resp.data.data;
    },
    clearPlayground({ commit }) {
        commit('CLEAR_PLAYGROUND');
    },
    async createVector({ rootGetters }, payload) {
        await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/vector-store', payload);
    },
    async getvectorFile({ rootGetters, commit }) {
        const resp = await httpPlainBotApi.get('/' + rootGetters.appId.hashed + '/vector-store');
        commit('SET_VECTORFILES', resp.data.data);
    },
    async deletevectorFile({ rootGetters, commit }, id) {
        const resp = httpPlainBotApi.delete('/' + rootGetters.appId.hashed + '/vector-store/' + id);
        console.log(resp);
    },
    async attachfileVector({ rootGetters }, payload) {
        await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/vector/' + payload.id, {
            file: payload.file,
            assistant_id: payload.assistant_id
        });
    },
    async detachfileVector({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.post('/' + rootGetters.appId.hashed + '/vector/' + payload.id + '/detach', {
            file: payload.file
        });
        return resp;
    },
    async updateVectorName({ rootGetters }, payload) {
        const resp = await httpPlainBotApi.put('/' + rootGetters.appId.hashed + '/vector-store/' + payload.id.id, { name: payload.name });
        console.log(resp);
    },
    async crawl(_, payload) {
        const resp = await httpClientBot.post('/nlp/crawl', { url: payload.url });
        console.log(resp);
        return resp;
    },
    async scrape(_, payload) {
        const resp = await httpClientBot.post('/nlp/scrape-v2', payload);
        console.log(resp);
        return resp;
    },
    async getModels({ rootGetters, commit }) {
      const resp = await httpPlainBotApi.get('/' + rootGetters.appId.hashed + '/model');
      commit('SET_MODELS', resp.data.data);
      return resp.data.data;
    },
    threadUnique(payload) {
      commit('SET_THREAD_UNIQUE', payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
